export let priceMixin = {
    methods:{
        // conversion Prix 2 chiffres après la virgules
        roundNumber(price){
            let roundNumber = Number(price).toFixed(1)
            let string= roundNumber.toString().split('.')
            if (string[1]==0){
                return Number(price).toFixed(0)
            }else{
                return Number(price).toFixed(1)
            }
        }
    }
}